<template>
    <div class="induce_modal" :class="dimcss">
        <h1 class="induce_modal_title font_white">어플레이즈 앱으로 더욱 쉽게</h1>
        <p class="induce_modal_desc font_white">앱에서 더 편하게 이용해보세요.</p>
        <img class="induce_modal_img" src="/media/img/experience/aplayz_introduce.png" alt="aplayz_introduce" />
        <button class="appinstall_downlaod title_lg font_white" @click="appdownload">앱 다운로드</button>
        <p class="today_notshow cursor" @click="todayStay">불편해도 웹으로 들을래요.</p>
    </div>
</template>
<script>
export default {
  name: 'InstallInduce',
  emits: ['today-stay'],
  props: {
    dimcss: {
      type: String,
      required: false,
      default: 'allblack'
    }
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {
    appdownload () {
      const agent = navigator.userAgent.toLowerCase();

      if (agent.indexOf('iphone') > -1 || agent.indexOf('ipad') > -1 || agent.indexOf('ipod') > -1) {
        window.open('https://apps.apple.com/kr/app/aplayz/id6446978790', 'blank');
      } else {
        window.open('https://play.google.com/store/apps/details?id=com.mobileaos.aplayz', 'blank');
      }
    },
    todayStay () {
      this.$emit('today-stay');
    }
  }
};
</script>
<style scoped src="@/assets/css/experience/installInduce.css"></style>
