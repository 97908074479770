<template>
    <div>
        <!-- Modal -->
        <home-modal v-if="isOpenHomeModal" @close-modal="onCloseHomeModal" />
        <!--Header-->
        <home-header
            :activeindex="activeIndex"
            :headercss="'experience'"
            @open-inquiry="isOpenHomeModal = true"
            @click-nav="onClickNav"
        />
        <main class="experience_main pc dp_flex">
            <player-curationinfo :atomsphere="getplaceatmosphere" :sectors="getsectors" :curationlist="getcurationlist" />
            <ul class="aplayz_player_group">
                <li>
                    <myplayer-left
                        :usertype="99"
                        :musicinfo="musicInfo"
                        @playcontrol="openIsShowSign"
                        @click-like="openIsShowSign"
                        @click-hate="openIsShowSign"
                        @click-comment="openIsShowSign"
                        @click-report="openIsShowSign"
                    />
                </li>
                <li class="player_right_wrap">
                    <myplayer-right
                        :popularchannellist="popularChannelList"
                        :otherchannellist="otherChannelList"
                        :updatedate="getRecommDateYYMMDDHHMM"
                        :clickchannel="onJoinChannel"
                    />
                </li>
            </ul>
        </main>
        <main class="experience_main mo dp_flex">
            <install-induce @today-stay="todayStay" />
        </main>
        <social-signup v-if="isShowSign" @close-modal="isShowSign = false" />
    </div>
</template>
<script>
import { nonMemberFixedChannel, getNonmemberChannelMusicInfo } from '@/service/api/userApi';
// import MainHeader from '@/components/home/MainHeader.vue';
import PlayerCurationInfo from '@/components/player/PlayerCurationInfo.vue';
import MyPlayerLeft from '@/components/player/MyPlayerLeft.vue';
import MyPlayerRight from '@/components/player/MyPlayerRight.vue';
import SocialSignUp from '@/components/join/SocialSignUp.vue';
import InstallInduce from '@/components/modal/experience/InstallInduce.vue';
import { setBg } from '@/utils/Utils';
export default {
  name: 'ExperienceView',
  components: {
    // 'main-header': MainHeader,
    'home-header': () => import(`@/components/home/HomeHeader.vue`),
    'player-curationinfo': PlayerCurationInfo,
    'myplayer-left': MyPlayerLeft,
    'myplayer-right': MyPlayerRight,
    'social-signup': SocialSignUp,
    'install-induce': InstallInduce,
    'home-modal': () => import(`@/components/home/HomeModal.vue`)
  },
  created () {
    this.allChannelList();
    this.addCurationIndex();
    this.$store.commit('player/setRecommInterval');
    this.$store.commit('experience/setNonSpaceClick', this.openIsShowSign);
  },
  data () {
    return {
      curationIndex: 0,
      musicInfo: {
        albumImg: '',
        musicNm: '',
        artist: '',
        musicId: ''
      },
      recomId: '',
      musicInfoInterval: -1,
      popularChannelList: [],
      otherChannelList: [],
      isShowSign: false,
      isMounted: false,
      isOpenHomeModal: false,
      activeIndex: 5
    };
  },

  mounted () {
    document.querySelector('body').classList.add('full-height');
    //
  },

  methods: {
    onCloseHomeModal () {
      this.isOpenHomeModal = false;
    },
    todayStay () {
      this.$router.push({ name: 'Home' }).catch(() => {});
    },
    goIntroduce () {
      this.$router.push({ name: 'Home', params: { eleid: 'introduce' } }).catch(() => {});
    },
    openIsShowSign () {
      this.isShowSign = !this.isShowSign;
    },
    onJoinChannel (item) {
      const { recomId, imgUrl } = item;
      setBg(imgUrl);
      this.recomId = recomId;
      this.getMusicInfo();
    },
    setMusicInfoInterval () {
      this.musicInfoInterval = setInterval(() => {
        this.getMusicInfo();
      }, 5000);
    },
    getMusicInfo () {
      getNonmemberChannelMusicInfo(this.recomId).then(res => {
        const { resultCd: channelMusicInfoResultCd, result: musicinfo } = res.data;
        if (channelMusicInfoResultCd === '0000') {
          if (this.musicInfo.musicId !== musicinfo.musicId) {
            this.musicInfo = musicinfo;
          }
        } else {
          this.musicInfo = {
            albumImg: '',
            musicNm: '',
            artist: '',
            musicId: ''
          };
        }
      });
    },
    addCurationIndex () {
      setInterval(() => {
        this.curationIndex++;
        if (this.curationIndex >= this.$store.getters['experience/getLabeltags'].length) this.curationIndex = 0;
      }, 3000);
    },
    async allChannelList () {
      try {
        const { data } = await nonMemberFixedChannel();
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          const { recomChannels, otherChannels } = result;
          this.otherChannelList = otherChannels;
          this.popularChannelList = recomChannels;
          const fisrt = this.popularChannelList[0];
          const { recomId, imgUrl } = fisrt;
          if (!this.isMounted) {
            setBg(`/media/download/dummyimages/default_img.jpg`);
          } else {
            setBg(imgUrl);
            this.isMounted = true;
          }
          this.recomId = recomId;
          this.getMusicInfo();
          this.setMusicInfoInterval();
        }
      } catch (error) {
        console.error('allChannelList : ', error);
      }
    },
    onClickNav (params) {
      const { id, index } = params;
      this.$router.push({ name: 'Home', params: { id, index } }).catch(() => {});
    }
  },
  computed: {
    getRecommDateYYMMDDHHMM () {
      return this.$store.getters['player/getRecommDateHHMM'];
    },
    getcurationlist () {
      return this.$store.getters['experience/getLabeltags'][this.curationIndex];
    },
    getplaceatmosphere () {
      return this.$store.getters['experience/getPlaceAtomsphere'][this.curationIndex];
    },
    getsectors () {
      return this.$store.getters['experience/getSectors'][this.curationIndex];
    }
  },
  destroyed () {
    clearInterval(this.musicInfoInterval);
  }
};
</script>
<style scoped src="@/assets/css/experience/experienceview.css"></style>
