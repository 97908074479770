<template>
    <div class="modal_bg">
        <div class="modal_center">
            <img
                class="modal_close_btn cursor"
                @click="$emit('close-modal')"
                src="/media/img/close_btn.png"
                alt="close_btn"
            />
            <h1 class="social_modal_title">
                음악으로 내 공간의 분위기를 <br />
                완성해보세요.
            </h1>
            <ul class="social_list">
                <li class="social_wrap social_naver cursor" @click="selectNaver">
                    <img class="social_naver_img" src="/media/img/visitor/naver_icon.svg" alt="네이버" />
                    NAVER로 계속하기
                </li>
                <li class="social_wrap social_kakao cursor" @click="selectKakao">
                    <img class="social_naver_img" src="/media/img/visitor/kakao_icon.svg" alt="카카오" />
                    Kakao로 계속하기
                </li>
                <li class="social_wrap social_apple cursor" @click="selectApple">
                    <img class="social_naver_img" src="/media/img/visitor/apple.png" alt="애플" />
                    Apple로 계속하기
                </li>
            </ul>
            <ul class="modal_sign">
                <span class="modal_sign_text cursor" @click="goJoin">회원가입</span>
                <div class="modal_sign_bar"></div>
                <span class="modal_sign_text cursor" @click="goLogin">로그인</span>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
  name: 'SocialSignUp',
  emits: ['close-modal'],
  data () {
    return {};
  },

  created () {
    this.getAppleJS();
  },
  mounted () {},

  methods: {
    selectNaver () {
      // 현재 주소 가져오기
      const url = window.location.href;
      // 주소에 따른 콜백 주소 변경
      const uri =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_CALLBACK_URL
          : process.env.VUE_APP_PRO_CALLBACK_URL;
      const clientId =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_NAVER_CRIENT_ID
          : process.env.VUE_APP_PRO_NAVER_CRIENT_ID;
      // state 난수생성
      const state = this.generateRandomString(20);
      const tag = document.createElement('a');
      tag.href =
        'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=' +
        clientId +
        '&redirect_uri=' +
        uri +
        '&state=' +
        state;
      tag.click();
    },
    selectKakao () {
      // 현재 주소 가져오기
      const url = window.location.href;
      const uri =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_CALLBACK_URL
          : process.env.VUE_APP_PRO_CALLBACK_URL;
      const clientId =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_KAKAO_CRIENT_ID
          : process.env.VUE_APP_PRO_KAKAO_CRIENT_ID;

      // a 태그 생성
      const tag = document.createElement('a');
      tag.href =
        'https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=' + clientId + '&redirect_uri=' + uri;

      // 카카오 로그인 페이지로 이동
      tag.click();
    },
    selectApple () {
      window.AppleID.auth.signIn();
    },
    getAppleJS () {
      const url = window.location.href;
      const uri =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_APPLE_CALLBACK_URL
          : process.env.VUE_APP_PRO_APPLE_CALLBACK_URL;
      const clientId =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_APPLE_CRIENT_ID
          : process.env.VUE_APP_PRO_APPLE_CRIENT_ID;

      window.AppleID.auth.init({
        clientId: clientId,
        scope: 'email',
        redirectURI: uri,
        usePopup: true // or false defaults to false
      });
    },
    generateRandomString (num) {
      // 네이버 인증 state 난수생성
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    goJoin () {
      this.$router.push({ name: 'SignUp' }).catch(() => {});
    },
    goLogin () {
      this.$router.push({ name: 'Login', params: { isShowSns: false } }).catch(() => {});
    }
  }
};
</script>
<style scoped>
.modal_bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9990;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_close_btn {
  top: 18px;
  right: 20px;
  position: absolute;
}

.modal_center {
  width: 381px;
  height: 381px;
  background-color: var(--272727);
  border-radius: 20px;
  padding: 0 24px;
  position: relative;
}

.social_modal_title {
  margin-top: 44px;
  font-weight: 700;
  color: var(--white);
  text-align: center;
  font-size: 24px;
  line-height: 36px;
}

.social_list {
  display: flex;
  margin-top: 32px;
  flex-direction: column;
}

.social_naver_img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.social_wrap {
  width: 100%;
  padding: 12px;
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  position: relative;
}

.social_naver {
  background-color: var(--03C75A);
  color: var(--white);
}

.social_kakao {
  background-color: var(--FEE500);
  color: var(--191600);
  margin-top: 8px;
}

.social_apple {
  background-color: var(--white);
  color: var(--000000);
  margin-top: 8px;
}

.modal_sign {
  width: 226px;
  margin: 0 auto;
  margin-top: 32px;
  display: flex;
  align-items: center;
}

.modal_sign_text {
  color: var(--white);
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
}

.modal_sign_bar {
  height: 12px;
  width: 1px;
  display: inline-block;
  background-color: var(--FFFFFF99);
  margin-left: auto;
  margin-right: auto;
}
</style>
