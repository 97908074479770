import Vue from 'vue';
import { isEqual } from 'lodash';
let animate = null;
const rolling = {
  bind: (el, binding) => {
    const { value } = binding;
    if (typeof value === 'string') {
      el.innerHTML = value;
    } else {
      const { str } = value;
      el.innerHTML = str;
    }
  },
  inserted: (el, binding) => {
    const { value, oldValue } = binding;
    if (typeof value === 'string') {
      if (value !== oldValue) {
        const { clientWidth, scrollWidth } = el;

        if (clientWidth < scrollWidth) {
          el.style.display = 'inline-block';
          for (let i = 0; i < 5; i++) {
            el.innerHTML += `&nbsp;&nbsp;&nbsp;${value}`;
          }
          animate = el.animate(
            [
              {
                transform: 'translateX(0)'
              },
              {
                transform: `translateX(-70%)`
              }
            ],
            {
              duration: el.scrollWidth * 30,
              iterations: Infinity
            }
          );
        }
      }
    } else {
      if (!isEqual(value, oldValue)) {
        let { str, speed, width } = value;
        el.innerHTML = str;
        const { scrollWidth } = el;
        if (!width) {
          width = el.clientWidth;
        }

        if (width < scrollWidth) {
          el.style.display = 'inline-block';
          for (let i = 0; i < 5; i++) {
            el.innerHTML += `&nbsp;&nbsp;&nbsp;${str}`;
          }
          animate = el.animate(
            [
              {
                transform: 'translateX(0)'
              },
              {
                transform: `translateX(-70%)`
              }
            ],
            {
              duration: el.scrollWidth * speed,
              iterations: Infinity
            }
          );
        }
      } else {
        el.style.display = 'block';
        if (animate) {
          const animation = el.getAnimations();
          if (animation.length > 0) {
            animation.forEach(item => {
              if (item.playState !== 'running') {
                item.cancel();
              }
            });
          }
        }
      }
    }
  },
  update: (el, binding) => {
    const { value, oldValue } = binding;
    if (typeof value === 'string') {
      if (value !== oldValue) {
        el.innerHTML = value;
        const { clientWidth, scrollWidth } = el;
        if (clientWidth < scrollWidth) {
          el.style.display = 'inline-block';
          for (let i = 0; i < 5; i++) {
            el.innerHTML += `&nbsp;&nbsp;&nbsp;${value}`;
          }
          animate = el.animate(
            [
              {
                transform: 'translateX(0)'
              },
              {
                transform: `translateX(-70%)`
              }
            ],
            {
              duration: el.scrollWidth * 30,
              iterations: Infinity
            }
          );
        } else {
          el.style.display = 'block';
          if (animate) {
            const animation = el.getAnimations();
            if (animation.length > 0) {
              animation.forEach(item => {
                item.cancel();
              });
            }
          }
        }
      }
    } else {
      let { str, speed, width } = value;

      if (!isEqual(value, oldValue)) {
        el.innerHTML = str;
        const { scrollWidth } = el;
        el.style.display = 'block';
        if (!width) {
          width = el.clientWidth;
        }
        if (width < scrollWidth) {
          el.style.display = 'inline-block';
          for (let i = 0; i < 5; i++) {
            el.innerHTML += `&nbsp;&nbsp;&nbsp;${str}`;
          }
          animate = el.animate(
            [
              {
                transform: 'translateX(0)'
              },
              {
                transform: `translateX(-70%)`
              }
            ],
            {
              duration: el.scrollWidth * speed,
              iterations: Infinity
            }
          );
        } else {
          el.style.display = 'block';
          if (animate) {
            const animation = el.getAnimations();
            if (animation.length > 0) {
              animation.forEach(item => {
                item.cancel();
              });
            }
          }
        }
      } else {
        const animation = el.getAnimations();
        if (animation.length > 0) {
          animation.forEach(item => {
            const { effect } = item;
            const { target } = effect;
            if (width >= target.scrollWidth) {
              item.cancel();
            }
          });
        }
      }
    }
  },
  componentUpdated: () => {},
  unbind: () => {}
};

export default rolling;
Vue.directive('rolling', rolling);
