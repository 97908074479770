<template>
    <div>
        <div class="live_wrap">
            <p class="live_text_wrap">
                <span class="streaming_wrap">
                    <img src="/media/img/streaming.png" alt="streaming" class="streaming" />
                </span>
                <span class="live_text">
                    {{ toptext }}
                </span>
            </p>
        </div>
        <div class="aplayz_header_group">
            <div
                class="cursor"
                v-if="channeltype === 'T' && usertype !== USER_TYPE.END_FREE_NO_SPACE"
                @click="$emit('click-bookmark')"
            >
                <img
                    class="star"
                    :src="isbookmarkcheck ? '/media/img/star_after.png' : '/media/img/star_before.png'"
                    alt="즐겨찾기"
                />
            </div>
            <h1 class="aplayz_channel_title_color header_lg" v-if="!channelname">
                <span class="aplayz_channel_title_color header_lg" ref="channel_title">{{ atomsphere }} {{ sectors }}</span
                >에 어울리는 음악은?
            </h1>
            <h1 class="aplayz_channel_title_color header_lg" v-else>
                {{ channelname }}
            </h1>
        </div>
        <ul class="tag_list" v-show="usertype !== USER_TYPE.END_FREE_NO_SPACE">
            <li
                v-if="issetting"
                class="tag_list_item header_sm cursor"
                :class="{ setting: getOpenCuration }"
                @click="$emit('open-curation')"
            >
                <img :src="curationSettingIcon" alt="큐레이션 설정" class="settings_icon" />
            </li>
            <li
                class="tag_list_item header_sm"
                v-for="(item, index) in curationlist"
                :key="`tag${index}`"
                :class="{ more: typeof item.value === 'object' && item.value.length > 1 }"
            >
                <template v-if="typeof item.value === 'object' && item.value.length > 1">
                    <template v-if="!item.isOpen">
                        {{ item.value[0] }}
                    </template>
                    <template v-else>
                        <span class="tag_list_item_array">{{ item.value.join(', ') }}</span>
                    </template>
                    <svg
                        class="more_icon cursor"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        v-if="!item.isOpen"
                        @click="opentag(index, item.isOpen)"
                    >
                        <circle cx="11" cy="11" r="11" fill="white" fill-opacity="0.28" />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.45947 16.3573L9.36435 17.25L15 11.5376L14.9883 11.526L15 11.5145L9.36435 5.80204L8.45947 6.69476L13.2258 11.526L8.45947 16.3573Z"
                            fill="white"
                        />
                    </svg>
                    <svg
                        class="more_icon cursor"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        v-else
                        @click="opentag(index, item.isOpen)"
                    >
                        <circle cx="11" cy="11" r="11" transform="rotate(-180 11 11)" fill="white" fill-opacity="0.28" />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.5405 5.64272L12.6357 4.75L7 10.4624L7.01171 10.474L7 10.4855L12.6357 16.198L13.5405 15.3052L8.7742 10.474L13.5405 5.64272Z"
                            fill="white"
                        />
                    </svg>
                </template>
                <template v-else>
                    <span v-if="typeof item.value === 'object'" class="tag_list_item_array">{{ item.value[0] }}</span>
                    <span v-else class="tag_list_item_array">{{ item.value }}</span>
                </template>
            </li>
        </ul>
        <ul class="tag_list" v-show="usertype === USER_TYPE.END_FREE_NO_SPACE">
            <li class="tag_dummy">
                <img class="tag_dummy_img" src="/media/img/player/spacedummycurationimg.png" alt="spacedummycurationimg" />
            </li>
        </ul>
    </div>
</template>
<script>
import { USER_TYPE } from '@/assets/js/usertype';
export default {
  name: 'PlayerCurationInfo',
  emits: ['click-bookmark', 'open-curation'],
  props: {
    usertype: {
      type: Number,
      required: false,
      default: 99
    },
    toptext: {
      type: String,
      required: false,
      default: '실시간'
    },
    channelname: {
      type: String,
      required: false,
      default: ''
    },
    atomsphere: {
      type: String,
      required: false,
      default: '아기자기한'
    },
    sectors: {
      type: String,
      required: false,
      default: '카페'
    },
    curationtext: {
      type: String,
      required: false,
      default: ''
    },
    curationlist: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    opentag: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    },
    isbookmarkcheck: {
      type: Boolean,
      required: false,
      default: false
    },
    channeltype: {
      type: String,
      required: false,
      default: ''
    },
    issetting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      USER_TYPE: USER_TYPE
    };
  },

  mounted () {},

  updated () {
    if (this.$refs.channel_title) this.$refs.channel_title.classList.add('fade');

    setTimeout(() => {
      if (this.$refs.channel_title) this.$refs.channel_title.classList.remove('fade');
    }, 2000);
  },

  methods: {},

  computed: {
    getOpenCuration () {
      const settingSpaceYn = this.$store.getters['player/getSettingSpaceYn'];
      if (settingSpaceYn !== 'Y') {
        return true;
      }
      return false;
    },
    curationSettingIcon () {
      const settingSpaceYn = this.$store.getters['player/getSettingSpaceYn'];
      if (settingSpaceYn === 'Y') {
        return `/media/img/webCastPlayer/refresh.png`;
      } else {
        return '/media/img/webCastPlayer/curation_setting_off.svg';
      }
    }
  }
};
</script>
<style scoped src="@/assets/css/player/playercurationinfo.css"></style>
<style scoped>
.fade {
  animation: fadein 3s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
